export default class ProjectAttachment {
    public projectId: string;
    public name: string;
    public type: string;
    public viewCount: number;
    public status: string;
    public statusDate: string;
    public visibleToCustomer: boolean;
    public processingStatus: string;
    public path: string;
}