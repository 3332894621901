<dialog-action-card
    [icon]="'preview'"
    [title]="data.length >= 2 ? translate.instant('shared.pdfFileViewerDialog.photoReports') : translate.instant('shared.pdfFileViewerDialog.preview')"
    [subTitle]="data[0].status === 'processing' && translate.instant('shared.pdfFileViewerDialog.photoReportsBackground')"
    [isLoaded]="true"
    (close)="onClose()">
    <div fxLayout="row" fxFill class="pdf-viewer-main-container">
        <div fxLayout="row" fxFill *ngIf="data.length">
            <div fxLayout="column" fxFlex="35">
                <mat-selection-list class="options-container" style="width: 100%" [multiple]="false" (selectionChange)="loadPdfFile($event)">
                    <mat-list-option class="list-container" *ngFor="let item of data" [value]="item">
                        <div fxLayout="row" fxLayout="center center" >
                            <div [matTooltip]="item.fileName" class="list-details" fxFlex>
                                <p class="file-name">{{item.fileName}}</p>
                                <span> {{item.lastModifiedDate | date}} </span>
                            </div>
                            <div class="list-actions" fxFlex="10">
                                <button *ngIf="item.status === 'done' || !item.status" (click)="downloadAttachment(item)" class="list-action" mat-icon-button color="primary" aria-label="download button"[matTooltip]="translate.instant('projects.attachments.tooltips.download')">
                                    <mat-icon>cloud_download</mat-icon>
                                </button>
                                <div class="list-action" style="padding: .2em;" *ngIf="item.status === 'processing'">
                                    <div fxLayout="column" fxLayoutAlign="center center" fxFill>
                                        <dots-loader></dots-loader>
                                    </div>
                                </div>
                                <div *ngIf="item.status === 'failed'" class="list-action" fxLayout="row" fxLayoutAlign="center center" mat-icon-button color="error" aria-label="error">
                                    <mat-icon class="failed-report" [matTooltip]="translate.instant('projects.attachments.tooltips.failed')">error</mat-icon>
                                </div>
                            </div>
                    </div>
                    </mat-list-option>
                  </mat-selection-list>
            </div>
            <div fxLayout="column" fxFlex="65">
                <div style="height: 100%" >
                    <ng-container [ngTemplateOutlet]="attachmentType === 'pdf' ? pdfViewer : imageAttachmentTypes.includes(attachmentType) ? imgViewer : msAttachmentTypes.includes(attachmentType) ? msViewer : attachmentType === 'csv' ? csvViewer : attachmentType === 'txt' ? txtViewer : attachmentType === 'msg' ? emailViewer : noViewer "></ng-container>
                    <ng-template #pdfViewer>
                        <pdf-viewer
                        [src]="fileSrc"
                        [render-text]="true"
                        [show-borders]="true"
                        style='height: inherit;'
                        >
                    </pdf-viewer>
                    </ng-template>
                    <ng-template #imgViewer>
                        <div style="height: 100%">
                            <img class="img-viewer" [src]="fileSrc"  />
                        </div>
                    </ng-template>
                    <ng-template #msViewer>
                        <div class="ms-viewer" >
                            <iframe [frameBorder]="0" class="viewer" [src]="fileSrc | safe"></iframe>
                        </div>
                    </ng-template>
                    <ng-template #csvViewer>
                        <div class="csv-viewer">
                            <kendo-grid [data]="csvData" [groupable]="false" sortable="true">
                            </kendo-grid>
                        </div>
                    </ng-template>
                    <ng-template #txtViewer>
                        <div class="ms-viewer" >
                            <iframe [frameBorder]="0" class="viewer" [src]="fileSrc | safe"></iframe>
                        </div>
                    </ng-template>
                    <ng-template #emailViewer>
                        <div class="email-viewer">
                            <div class="email-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em">
                                    <div class="email-sender-avatar" fxFlex="6">
                                        {{ emailData?.senderName[0] }}
                                    </div>
                                <div class="email-details" fxFlex="94">
                                    <div class="email-details-header" fxLayout="row" fxLayoutAlign="start space-between">
                                    <span>{{ emailData?.senderName }}</span>
                                    <span class="email-date"> {{ emailData?.creationTime }}</span>
                                    </div>
                                    <div class="email-recipients" fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center">
                                        <span>TO:</span>
                                        <ul class="email-recipients-list" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center">
                                            <ng-container *ngFor="let recipient of emailData?.recipients">
                                                <li [matTooltip]="recipient.email">{{recipient.name.split(" ")[0]}};</li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <span class="email-subject">{{ emailData?.subject }}</span>
                            <div class="email-body">{{ emailData?.body }}</div>
                        </div>
                    </ng-template>
                    <ng-template #noViewer>
                        <div class="no-viewer">
                            <p>{{ translate.instant('projects.attachments.unableToPreview') }}</p>
                        </div>
                    </ng-template>                    
                </div>
            </div>
        </div>
        <div *ngIf="!data.length" fxLayout="column" fxFill fxLayoutAlign="center center" class="pdf-viewer-empty-container">
            <label>{{ 'shared.pdfFileViewerDialog.noPhotoReportIndicator' | translate }}</label>
        </div>
    </div>
</dialog-action-card>
