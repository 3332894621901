import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import FileListRecord from '../../../../core/data-models/file-list';
import { DownloadHttpService } from '../../../../core/services/http/download-http/download-http.service';
import { HttpClient } from '@angular/common/http';
import MsgReader from '@kenjiuno/msgreader';

@Component({
    selector: 'app-pdf-file-viewer-dialog',
    styleUrls: ['./pdf-file-viewer-dialog.component.scss'],
    templateUrl: './pdf-file-viewer-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PdfFileViewerDialogComponent implements OnInit {
    public fileSrc: string;
    public attachmentType: string;
    public imageAttachmentTypes = [	'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'webp'];
    public msAttachmentTypes = ['doc', 'docx', 'xlsx', 'xls', 'xlsm', 'xltx'];
    public csvData;
    public loadedAttachment: FileListRecord;
    public emailData;

    // eslint-disable-next-line max-params
    constructor(public translate: TranslateService,
        private _dialog: MatDialogRef<PdfFileViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FileListRecord[],
        private _downloadService: DownloadHttpService,
        private _snackBar: MatSnackBar,
        private http: HttpClient
    ) { }

    public ngOnInit(): void {
        this.attachmentType = this.getExtensionFromFileName(this.data[0].fileName);
        const fileUrl = this.data[0].fileUrl;
        this.loadedAttachment = this.data[0];
        if (this.msAttachmentTypes.includes(this.attachmentType)) {
            this.fileSrc = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;
        }
        else if (this.attachmentType === 'csv') {
            this.getCsvFromUrlAndConvertToJson(fileUrl);
        }
        else if (this.attachmentType === 'msg') {
            this.readEmailData(fileUrl);
        }
        else {
            this.fileSrc = fileUrl;
        }
    }

    public loadPdfFile(selected: MatSelectionListChange): void {
        this.attachmentType = this.getExtensionFromFileName(selected.options[0].value.fileName);
        const fileUrl = selected.options[0].value.fileUrl;
        this.loadedAttachment = selected.options[0].value;

        if (this.msAttachmentTypes.includes(this.attachmentType)) {
            this.fileSrc = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`;
        }
        else if (this.attachmentType === 'csv') {
            this.getCsvFromUrlAndConvertToJson(fileUrl);
        }
        else if (this.attachmentType === 'msg') {
            this.readEmailData(fileUrl);
        }
        else {
            this.fileSrc = fileUrl;
        }
    }

    public onClose(): void {
        this._dialog.close();
    }

    public async downloadAttachment(item: FileListRecord) {
        const downloaded = await this._downloadService.download(item.fileUrl, item.fileName);
        if (!downloaded) {
            const failedToUpload = this.translate.instant('projects.attachments.failedToDownload');
            const close = this.translate.instant('snackbars.close');
            this._snackBar.open(`${failedToUpload} ${item.fileUrl}`, close);
        }
    }

    private getCsvFromUrlAndConvertToJson(url: string) {
        this.http.get(url, { responseType: 'text' })
            .subscribe(
                data => {
                    this.csvData = this.csvToJson(data);
                },
                _ => {
                    const ok = this.translate.instant('snackbars.ok');
                    this._snackBar.open(this.translate.instant('receipts.failedToPreview', { fileName: this.loadedAttachment.fileName }), ok);
                }
            );
    }

    private csvToJson(text, quoteChar = '"', delimiter = ',') {
        // eslint-disable-next-line no-param-reassign
        text = text.trim();
        const rows = text.split('\n');
        const headers = rows[0].split(',');

        const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

        function match(line) {
            const matches = [...line.matchAll(regex)].map(m => m[2]);
            // Ensure matches length matches headers length by padding with null values
            const paddedMatches = Array.from({ length: headers.length }, (_, i) => matches[i] ?? null);
            return paddedMatches;
        }

        let lines = text.split('\n');
        const heads = headers ?? match(lines.shift());
        lines = lines.slice(1);

        return lines.map(line => {
            return match(line).reduce((acc, cur, i) => {
                // replace blank matches with `null`
                const val = cur === null || cur.length <= 0 ? null : Number(cur) || cur;
                const key = heads[i] ?? '{i}';
                return { ...acc, [key]: val };
            }, {});
        });
    }


    private getExtensionFromFileName(fileName: string) {
        const regex = /(?:\.([^.]+))?$/;
        return regex.exec(fileName)[1];
    }

    private readEmailData(url: string) {
        this.http.get(url, { responseType: 'arraybuffer' })
            .subscribe(
                data => {
                    this.emailData = new MsgReader(data).getFileData();
                },
                _ => {
                    const ok = this.translate.instant('snackbars.ok');
                    this._snackBar.open(this.translate.instant('receipts.failedToPreview', { fileName: this.loadedAttachment.fileName }), ok);
                }
            );
    }

}
